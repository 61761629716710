import { Accordion, Title, Stack, SimpleGrid, NumberInput, ColorInput, Group, TextInput } from "@mantine/core";
import Konva from "konva";

export interface InspectorProps {
  node: Konva.Node;
}

export const CommonInspector = (props: InspectorProps) => {
  return (
    <Accordion.Item value="common">
      <Accordion.Control py={8}>
        <Title order={6}>Genel</Title>
      </Accordion.Control>
      <Accordion.Panel>
        <Stack spacing={"xs"}>
          <SimpleGrid cols={2}>
            <ColorInput
              label="Dolgu"
              name="fill"
              value={props.node.getAttr("fill")}
              size="xs"
              onChange={(color) => {
                props.node.setAttr("fill", color);
              }}
            />
            <ColorInput
              label="Kontör"
              name="stroke"
              value={props.node.getAttr("stroke")}
              size="xs"
              onChange={(color) => {
                props.node.setAttr("stroke", color);
              }}
            />
          </SimpleGrid>
          <SimpleGrid cols={4}>
            <NumberInput
              size="xs"
              label="X"
              value={props.node.getAttr("x")}
              onChange={(val) => {
                props.node.setAttr("x", val);
              }}
            />

            <NumberInput
              size="xs"
              label="Y"
              value={props.node.getAttr("y")}
              onChange={(val) => {
                props.node.setAttr("y", val);
              }}
            />

            <NumberInput
              size="xs"
              label="En"
              value={props.node.getAttr("width")}
              onChange={(val) => {
                props.node.setAttr("width", val);
              }}
            />
            <NumberInput
              size="xs"
              label="Boy"
              value={props.node.getAttr("height")}
              onChange={(val) => {
                props.node.setAttr("height", val);
              }}
            />

            <NumberInput
              size="xs"
              label="Rotation"
              value={props.node.getAttr("rotation")}
              onChange={(val: number) => {
                props.node.rotation(val);
              }}
            />

            <NumberInput
              size="xs"
              label="Kontör"
              value={props.node.getAttr("strokeWidth")}
              onChange={(val) => {
                props.node.setAttr("strokeWidth", val);
              }}
            />
          </SimpleGrid>
        </Stack>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
