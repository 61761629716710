import { Accordion, ActionIcon, Box, Container, Stack, Title, Text } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import Konva from "konva";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/appContext";
import { CommonInspector } from "./Inspectors/CommonInspector";
import { TextInspector } from "./Inspectors/TextInspector";

export interface InspectbarProps {
  node?: Konva.Node;
}

export const Inspectbar = (props: InspectbarProps) => {
  const app = useContext(AppContext);
  const [inspectbarVisible, setInspectbarVisible] = useState(false);
  const [dirty, setDirty] = useState(0);
  const [selectedNode, setSelectedNode] = useState<Konva.Node | null>(null);
  const [attrs, setAttrs] = useState<any>(null);

  const toggleInspectBar = () => {
    setInspectbarVisible(!inspectbarVisible);
  };

  // Herhangi bir nesne seçildiği zaman
  // bu alanı tekrardan render edeceğiz
  app?.engine?.stage.on("click", (e) => {
    switch (e.target.getType()) {
      case "Shape":
        setSelectedNode(e.target);
        //console.log(typeof e.target);
        break;

      default:
        setSelectedNode(null);
        break;
    }
  });

  useEffect(() => {
    setAttrs(selectedNode?.getAttrs());

    // console.log(selectedNode?.getClassName());

    const interval = setInterval(() => {
      setAttrs(selectedNode?.getAttrs());
    }, 100);
    // return () => {
    //   return clearInterval(interval);
    // };
  }, [selectedNode, attrs]);

  return (
    <>
      <ActionIcon variant="filled" radius={0} size={"xs"} className="toolbar-expand-button" onClick={toggleInspectBar}>
        {inspectbarVisible ? <IconChevronRight /> : <IconChevronLeft />}
      </ActionIcon>
      {inspectbarVisible && (
        <Box id="inspectbar">
          {selectedNode ? (
            <Accordion multiple={true}>
              <CommonInspector node={selectedNode} />

              {selectedNode?.getClassName() === "Text" && <TextInspector node={selectedNode} />}

              {/* <Accordion.Item value="animation">
                <Accordion.Control py={8}>
                  <Title order={6}>Animasyon</Title>
                </Accordion.Control>
                <Accordion.Panel>özellikler</Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="colors">
                <Accordion.Control py={8}>
                  <Title order={6}>Renkler</Title>
                </Accordion.Control>
                <Accordion.Panel>Colors</Accordion.Panel>
              </Accordion.Item> */}

              <Accordion.Item value="data">
                <Accordion.Control py={8}>
                  <Title order={6}>Data</Title>
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack>
                    <p>Text</p>
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          ) : (
            <Container p={16}>
              <Text>Lütfen bir nesne seçin</Text>
            </Container>
          )}
        </Box>
      )}
    </>
  );
};
