import { Image, Loader } from "@mantine/core";

export interface ToolbarThumbItem {
  type: "svg" | "image" | "text" | "video";
  previewUrl?: string;
  url?: string;
  thumb?: string;
  label?: string;
}

export interface ToolbarThumbProps {
  item: ToolbarThumbItem;
}

export const ToolbarThumb = (props: ToolbarThumbProps) => {
  return (
    <div className="toolbar-thumb">
      <Image
        src={props.item.previewUrl}
        alt=""
        width={80}
        height={80}
        withPlaceholder
        placeholder={<Loader color={"gray"} size={"sm"} variant={"dots"} />}
        caption={props.item.label}
      />
    </div>
  );
};
