import { Accordion, Title, Stack, NumberInput, Button, Group, NativeSelect, Textarea } from "@mantine/core";
import { IconAlignLeft, IconAlignCenter, IconAlignRight } from "@tabler/icons";
import Konva from "konva";
import { useEffect, useState } from "react";

export interface InspectorProps {
  node: Konva.Node;
}

enum TextAlignment {
  Left = "left",
  Right = "right",
  Center = "center",
  Justify = "justify",
}

export const TextInspector = (props: InspectorProps) => {
  const [alignment, setAlignment] = useState<TextAlignment>(props.node.getAttr("align"));
  const [fontFamilies, setFontFamilies] = useState<any[]>([]);
  useEffect(() => {}, [alignment]);

  return (
    <Accordion.Item value="text">
      <Accordion.Control py={8}>
        <Title order={6}>Metin</Title>
      </Accordion.Control>
      <Accordion.Panel>
        <Stack spacing={"xs"}>
          <Textarea
            size="xs"
            value={props.node.getAttr("text")}
            autosize
            minRows={2}
            onChange={(e) => {
              console.log(e.target.value);
              props.node.setAttr("text", e.target.value);
            }}
          />
          <Button.Group>
            <Button
              compact
              variant={alignment === TextAlignment.Left ? "filled" : "default"}
              size="xs"
              color={"gray"}
              onClick={() => {
                setAlignment(TextAlignment.Left);
              }}
            >
              <IconAlignLeft size={14} />
            </Button>
            <Button
              compact
              variant={alignment === TextAlignment.Center ? "filled" : "default"}
              size="xs"
              color={"gray"}
              onClick={() => {
                props.node.setAttr("align", alignment);
                setAlignment(TextAlignment.Center);
              }}
            >
              <IconAlignCenter size={14} />
            </Button>
            <Button
              compact
              variant={alignment === TextAlignment.Right ? "filled" : "default"}
              size="xs"
              color={"gray"}
              onClick={() => {
                props.node.setAttr("align", alignment);
                setAlignment(TextAlignment.Right);
              }}
            >
              <IconAlignRight size={14} />
            </Button>
            {/* <Button compact variant={alignment === "left" ? "filled" : "default"} size="xs" color={"gray"}>
              <IconAlignJustified size={14} />
            </Button> */}
          </Button.Group>
          <NativeSelect
            name="fontFamily"
            placeholder="Font"
            size="xs"
            data={[
              { value: "Arial", label: "Arial" },
              { value: "Helvetica", label: "Helvetica" },
              { value: "TimesNewRoman", label: "TimesNewRoman" },
            ]}
            onChange={(e) => {
              props.node.setAttr("fontFamily", e.target.value);
              console.log(e.target.value);
            }}
          />
          <Group grow spacing={"xs"}>
            <NativeSelect
              name="fontStyle"
              placeholder="Bold"
              size="xs"
              data={[
                { value: "normal", label: "Normal" },
                { value: "bold", label: "Bold" },
                { value: "Italic", label: "Italic" },
                { value: "Italic Bold", label: "Italic Bold" },
              ]}
              onChange={(e) => {
                props.node.setAttr("fontStyle", e.target.value);
                console.log(e.target.value);
              }}
            />
            <NumberInput
              name="fontSize"
              placeholder="Size"
              size="xs"
              value={props.node.getAttr("fontSize")}
              onChange={(value) => {
                props.node.setAttr("fontSize", value);
                console.log(value);
              }}
            />
          </Group>
        </Stack>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
