import { Grid, Stack, Title } from "@mantine/core";
import { ToolbarThumb, ToolbarThumbItem } from "./ToolbarThumb";

export const ToolbarTextsPane = () => {
  const shapes: ToolbarThumbItem[] = [];

  for (let a = 0; a < 100; a++) {
    shapes.push({
      type: "text",
      thumb: "text",
      // label: "triangle",
    });
  }

  return (
    <Stack>
      <Title order={5}>Metinler</Title>
      <Grid gutter={0}>
        {shapes.map((item: ToolbarThumbItem, index: number) => {
          return (
            <Grid.Col span={4} key={index} className="toolbar-thumb">
              <ToolbarThumb item={item} />
            </Grid.Col>
          );
        })}
      </Grid>
    </Stack>
  );
};
