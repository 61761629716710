import Konva from "konva";
import { v4 as uuidv4 } from "uuid";
import {
  DEFAULT_FILL_COLOR,
  DEFAULT_MODE,
  DEFAULT_STROKE_COLOR,
  DEFAULT_STROKE_WIDTH,
  DEFAULT_ZOOM,
  ENGINE_MODE,
} from "./defaults";

class Engine {
  stage: Konva.Stage;
  layers: Konva.Layer[] = [];
  activeLayer: Konva.Layer | null = null;
  transformer: Konva.Transformer | null = null;

  // Anlık oluşturma değerleri
  currentEngineMode: ENGINE_MODE = DEFAULT_MODE;
  currentFillColor: string | undefined = DEFAULT_FILL_COLOR;
  currentStrokeColor: string | undefined = DEFAULT_STROKE_COLOR;
  currentStrokeWidth: number | undefined = DEFAULT_STROKE_WIDTH;
  currentZoom: number = DEFAULT_ZOOM;

  pageWidth: number;
  pageHeight: number;

  constructor(width: number, height: number) {
    const stageElement = document.getElementById("stage");

    this.pageWidth = width;
    this.pageHeight = height;

    // Sayfanın stage alanında ortalanmasını sağlayalım
    const offsetX = stageElement ? stageElement.clientWidth / 2 - this.pageWidth / 2 : 0;
    const offsetY = stageElement ? stageElement.clientHeight / 2 - this.pageHeight / 2 : 0;

    this.stage = new Konva.Stage({
      container: "stage",
      width: stageElement?.clientWidth,
      height: stageElement?.clientHeight,
      offsetX: offsetX * -1,
      offsetY: offsetY * -1,
      draggable: true,
    });

    this.addBaseLayer();
    this.handleStageEvents();
    this.addPage(width, height);

    this.transformer = new Konva.Transformer();
    this.activeLayer?.add(this.transformer);
  }

  handleStageEvents() {
    // Sahnede bir yere tıklandığı zaman
    this.stage.on("click", (e) => {
      // eğer sahneye tıklanıyor ise
      if (e.target.getType() === "Stage") {
        this.transformer?.nodes([]);
        // Bir nesneye tıklandı
      } else {
        this.transformer?.nodes([e.target]);
        this.transformer?.moveToTop();
      }
    });

    const scaleBy = 1.04;
    this.stage.on("wheel", (e) => {
      // stop default scrolling
      e.evt.preventDefault();

      var oldScale = this.stage.scaleX();
      var pointer = this.stage.getPointerPosition();

      if (pointer != null) {
        var mousePointTo = {
          x: (pointer.x - this.stage.x()) / oldScale,
          y: (pointer.y - this.stage.y()) / oldScale,
        };

        // how to scale? Zoom in? Or zoom out?
        let direction = e.evt.deltaY > 0 ? -1 : 1;

        // when we zoom on trackpad, e.evt.ctrlKey is true
        // in that case lets revert direction
        if (e.evt.ctrlKey) {
          direction = -direction;
        }

        var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        this.stage.scale({ x: newScale, y: newScale });

        var newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
        this.stage.position(newPos);
      }
    });

    // Pencere yeniden boyutlandırıldığında
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        const stageElement = document.getElementById("stage");
        const stageWrapperElement = document.getElementById("stage-wrapper");
        if (stageWrapperElement) {
          // Sahnenin yeni boyutlarını hesapla
          this.stage.width(stageWrapperElement.clientWidth);
          this.stage.height(stageWrapperElement.clientHeight);

          // Sayfanın sahnenin ortasında kalması için yeni offseti hesapla
          const offsetX = stageElement ? stageElement.clientWidth / 2 - this.pageWidth / 2 : 0;
          const offsetY = stageElement ? stageElement.clientHeight / 2 - this.pageHeight / 2 : 0;
          this.stage.offsetX(-offsetX);
          this.stage.offsetY(-offsetY);
        }
      });
    }

    var container = this.stage.container();

    container.tabIndex = 1;
    container.focus();
    container.addEventListener("keydown", (e: KeyboardEvent) => {
      switch (e.key) {
        case "Backspace":
          this.transformer?.getNodes().map((item) => {
            item.destroy();
          });
          this.transformer?.nodes([]);
          break;
      }
    });
  }

  addLayer(id: string) {
    this.stage.add(
      new Konva.Layer({
        id,
      })
    );
  }

  addBaseLayer() {
    // Varsayılan layer oluştur
    this.layers = [
      new Konva.Layer({
        clipX: 0,
        clipY: 0,
        clipWidth: this.pageWidth,
        clipHeight: this.pageHeight,
      }),
    ];
    this.activeLayer = this.layers[0];
    this.stage.add(this.layers[0]);
    this.activeLayer.draw();
  }

  addShape(shape: Konva.Shape) {
    // ID yok ise random ekle
    if (shape.id().length === 0) {
      shape.id(uuidv4());
    }

    this.activeLayer?.add(shape);
    // this.transformer?.nodes([shape]);
  }

  addImage(imageUrl: string) {
    Konva.Image.fromURL(imageUrl, (image: Konva.Image) => {
      const ratio = image.getWidth() / image.getHeight();
      image.setAttrs({
        draggable: true,
        width: 200 * ratio,
        height: 200,
      });

      // console.log(image.getAttrs());

      // image.setAttr("offsetX", 200);
      // image.setAttr("offsetY", 200);

      this.activeLayer?.add(image);
      // this.transformer?.nodes([image]);
    });
  }

  addText(str: string) {
    const text = new Konva.Text({
      x: 100,
      y: 100,
      text: str,
      fontSize: 24,
      width: 200,
      fill: this.currentFillColor,
      stroke: this.currentStrokeColor,
      strokeWidth: this.currentStrokeWidth,
      draggable: true,
    });
    text.on("transform", () => {
      text.setAttrs({
        width: Math.max(text.width() * text.scaleX(), 200),
        scaleX: 1,
        scaleY: 1,
      });
    });
    this.activeLayer?.add(text);
  }

  addPage(width: number, height: number) {
    const page = new Konva.Rect({
      id: "page-1",
      width: width,
      height: height,
      fill: "#ffffff",
      stroke: "#e1e1e1",
      listening: false,
    });
    this.activeLayer?.add(page);
  }

  toData(): string | undefined {
    return this.activeLayer?.toDataURL({
      pixelRatio: 1.0,
      x: this.stage.offsetX() * -1 + this.stage.x(),
      y: this.stage.offsetY() * -1 + this.stage.y(),
      width: this.pageWidth,
      height: this.pageHeight,
      quality: 1,
    });
  }

  toJson() {
    return this.stage?.toJSON();
  }

  toImage() {
    return this.activeLayer?.toImage({
      pixelRatio: 1.0,
      x: this.stage.offsetX() * -1 + this.stage.x(),
      y: this.stage.offsetY() * -1 + this.stage.y(),
      width: this.pageWidth,
      height: this.pageHeight,
      quality: 1,
    });
  }

  downloadJson() {
    var data = this.toJson();
    this.downloadJsonData(JSON.stringify(data), "stage.json", "text/plain");
    // this.downloadURI(data as string, "stage.json");
  }

  downloadImage() {
    var data = this.toData();
    this.downloadURI(data as string, "stage.png");
  }

  downloadURI(uri: string, name: string) {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadJsonData(content: string, fileName: string, contentType: string) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }
}

export default Engine;
