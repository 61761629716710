import "./App.css";
import { useEffect, useState } from "react";
import Engine from "./libs/engine";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { AppContext } from "./context/appContext";
import { Toolbar } from "./components/Toolbar";
import { Inspectbar } from "./components/Inspectbar";

function App() {
  const [engine, setEngine] = useState<Engine | null>(null);

  useEffect(() => {
    setEngine(new Engine(640, 480));
  }, []);

  return (
    <div id="app">
      <AppContext.Provider value={{ engine }}>
        <div id="stage"></div>
        <div id="ui">
          <Header engine={engine} />
          <div id="content">
            <Toolbar engine={engine} />
            <div id="hole"></div>
            <Inspectbar />
          </div>
          <Footer engine={engine} />
        </div>
      </AppContext.Provider>
    </div>
  );
}

export default App;
