// Varsayılan değerler

export enum ENGINE_MODE {
  SELECT,
  PENCIL,
  TEXT,
  ZOOM,
}

export const DEFAULT_MODE = ENGINE_MODE.SELECT;
export const DEFAULT_FILL_COLOR = "#ee0000";
export const DEFAULT_STROKE_COLOR = "#000000";
export const DEFAULT_STROKE_WIDTH = 0;
export const DEFAULT_ZOOM = 1.0;

export type EnginePage = {
  width: number;
  height: number;
};
