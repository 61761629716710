import { Grid, LoadingOverlay, Stack, TextInput, Title } from "@mantine/core";
import { ToolbarThumb, ToolbarThumbItem } from "./ToolbarThumb";
import { useContext, useState } from "react";
import { AppContext } from "../context/appContext";
import { useForm } from "@mantine/form";
import Pixabay from "../api/pixabay";
import { IconSearch } from "@tabler/icons";

export const ToolbarShapesPane = () => {
  const app = useContext(AppContext);
  const [items, setItems] = useState<ToolbarThumbItem[]>([]);
  const [searching, setSearching] = useState(false);

  const pixabay = new Pixabay();

  const form = useForm({
    initialValues: {
      search: "",
    },
    validate: {},
  });

  return (
    <Stack>
      <Title order={5}>Şekiller</Title>
      <form
        onSubmit={form.onSubmit((values) => {
          setSearching(true);

          pixabay.getVectors(values.search).then((response) => {
            let items: ToolbarThumbItem[] = [];
            response.data.hits.map((item: any) => {
              console.log(item);
              items.push({
                type: "image",
                previewUrl: item.previewURL,
                url: item.previewURL,
              });
            });
            setItems(items);
            setSearching(false);
          });
        })}
      >
        <TextInput
          {...form.getInputProps("search")}
          rightSection={<IconSearch size={16} />}
          placeholder="Şekil arayın"
        />
      </form>

      <div style={{ position: "relative" }}>
        <LoadingOverlay visible={searching} overlayBlur={2} />
        <Grid gutter={"sm"}>
          {items.map((item: ToolbarThumbItem, index: number) => {
            return (
              <Grid.Col
                span={4}
                key={index}
                onClick={() => {
                  app?.engine?.addImage(item.url as string);
                }}
              >
                <ToolbarThumb item={item} />
              </Grid.Col>
            );
          })}
        </Grid>
      </div>
    </Stack>
  );
};
