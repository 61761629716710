import { ActionIcon, Box, Stack } from "@mantine/core";
import {
  IconCategory2,
  IconChevronLeft,
  IconChevronRight,
  IconCursorText,
  IconPhoto,
  IconPointer,
  IconSettings,
} from "@tabler/icons";
import Konva from "konva";
import { useContext, useState } from "react";
import { AppContext } from "../context/appContext";
import { DEFAULT_FILL_COLOR, DEFAULT_STROKE_COLOR } from "../libs/defaults";
import Engine from "../libs/engine";
import { ToolbarImagesPane } from "./ToolbarImagesPane";
import { ToolbarShapesPane } from "./ToolbarShapesPane";
import { ToolbarTextsPane } from "./ToolbarTextsPane";

export interface ToolbarProps {
  engine: Engine | null;
}

export const Toolbar = (props: ToolbarProps) => {
  const app = useContext(AppContext);

  const [toolbarPaneMode, setToolbarPaneMode] = useState<string | null>(null);
  const [toolbarPane, setToolbarPane] = useState(<></>);
  const [toolbarPaneVisible, setToolbarPaneVisible] = useState(false);

  // const [toolbarDetailVisible, setToolbarDetailVisible] = useState(false);
  const [currentFillColor] = useState<string | undefined>(DEFAULT_FILL_COLOR);
  const [currentStrokeColor] = useState<string | undefined>(DEFAULT_STROKE_COLOR);

  if (app && app.engine) {
    app.engine.currentFillColor = currentFillColor;
    app.engine.currentStrokeColor = currentStrokeColor;
  }

  const addRect = () => {
    app?.engine?.addShape(
      new Konva.Rect({
        x: 100,
        y: 100,
        width: 50,
        height: 50,
        fill: app.engine.currentFillColor,
        stroke: app.engine.currentStrokeColor,
        draggable: true,
      })
    );
  };

  const addCircle = () => {
    app?.engine?.addShape(
      new Konva.Circle({
        x: 100,
        y: 100,
        radius: 50,
        fill: app.engine.currentFillColor,
        stroke: app.engine.currentStrokeColor,
        draggable: true,
      })
    );
  };

  const addStar = () => {
    app?.engine?.addShape(
      new Konva.Star({
        x: 100,
        y: 100,
        numPoints: 6,
        innerRadius: 40,
        outerRadius: 70,

        fill: app.engine.currentFillColor,
        stroke: app.engine.currentStrokeColor,
        draggable: true,
      })
    );
  };

  const addText = () => {
    app?.engine?.addShape(
      new Konva.Text({
        x: 100,
        y: 100,
        text: "Octopus",
        fontSize: 60,
        fill: app.engine.currentFillColor,
        stroke: app.engine.currentStrokeColor,
        strokeWidth: 0,
        draggable: true,
      })
    );
  };

  // let toolbarPane = <></>;
  const toggleToolbarPane = (mode: string | null) => {
    if (toolbarPaneMode === mode && toolbarPaneVisible) {
      setToolbarPaneVisible(false);
      return;
    }

    setToolbarPaneMode(mode);
    setToolbarPaneVisible(true);
    switch (mode) {
      case "shapes":
        setToolbarPane(<ToolbarShapesPane />);
        break;
      case "images":
        setToolbarPane(<ToolbarImagesPane />);
        break;
      case "texts":
        setToolbarPane(<ToolbarTextsPane />);
        break;

      default:
        setToolbarPaneVisible(false);
        setToolbarPane(<ToolbarShapesPane />);
        break;
    }
  };

  const defaultText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras imperdiet semper posuere.";

  return (
    <>
      <Box id="toolbar">
        <Stack justify={"space-between"} style={{ height: "100%" }}>
          <Stack spacing={"xs"} align={"center"} py={10}>
            <ActionIcon onClick={() => toggleToolbarPane(null)}>
              <IconPointer />
            </ActionIcon>

            <ActionIcon onClick={() => toggleToolbarPane("shapes")}>
              <IconCategory2 />
            </ActionIcon>
            <ActionIcon onClick={() => toggleToolbarPane("images")}>
              <IconPhoto />
            </ActionIcon>
            <ActionIcon onClick={() => app?.engine?.addText(defaultText)}>
              <IconCursorText />
            </ActionIcon>
          </Stack>
          <Stack spacing={"xs"} align={"center"} py={10}>
            <ActionIcon>
              <IconSettings />
            </ActionIcon>
          </Stack>
        </Stack>
      </Box>
      {toolbarPaneVisible && <Box id="toolbar-pane">{toolbarPane}</Box>}
      <ActionIcon
        variant="filled"
        radius={0}
        size={"xs"}
        className="toolbar-expand-button"
        onClick={() => setToolbarPaneVisible(!toolbarPaneVisible)}
      >
        {toolbarPaneVisible ? <IconChevronLeft /> : <IconChevronRight />}
      </ActionIcon>
    </>
  );
};
