import { Box, Button, Group, Title } from "@mantine/core";
import { IconFileDownload, IconFileUpload } from "@tabler/icons";
import { useContext } from "react";
import { AppContext } from "../context/appContext";
import Engine from "../libs/engine";
export interface HeaderProps {
  engine: Engine | null;
}

export const Header = (props: HeaderProps) => {
  const app = useContext(AppContext);

  const HeaderGroupStyles = {
    padding: 10,
    alignItems: "center",
  };

  return (
    <Box id="header">
      <Group position="apart" style={{ flex: 1 }}>
        <div>
          <Title order={4}>Kodsal</Title>
        </div>
        <div>
          <Group>
            <Button variant="default" size="xs" leftIcon={<IconFileUpload size={20} />}>
              Yükle
            </Button>
            <Button
              variant="default"
              size="xs"
              leftIcon={<IconFileDownload size={20} />}
              onClick={() => app?.engine?.downloadImage()}
            >
              PNG
            </Button>
            <Button
              variant="default"
              size="xs"
              leftIcon={<IconFileDownload size={20} />}
              onClick={() => app?.engine?.downloadJson()}
            >
              DATA
            </Button>
          </Group>
        </div>
        <div></div>
      </Group>
    </Box>
  );
};
