import axios from "axios";

class Pixabay {
  axios: any;
  key: string = "30735912-4fcc95dcdd0691086d2d9d8b9";
  perPage: number = 25;
  constructor() {
    this.axios = axios.create({
      baseURL: "https://pixabay.com/api/",
      timeout: 5000,
      headers: {},
    });
  }

  async getVectors(term: string) {
    const query = `?key=${this.key}&q=${term}&safesearch=true&page=1&per_page=${this.perPage}&image_type=vector&colors=grayscale`;
    return await this.axios.get(query);
  }

  async getImages(term: string) {
    const query = `?key=${this.key}&q=${term}&safesearch=true&page=1&per_page=${this.perPage}&image_type=all`;
    return await this.axios.get(query);
  }
}

export default Pixabay;
