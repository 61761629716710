import { Button, Group, Box } from "@mantine/core";

import { useEffect, useState } from "react";
import { DEFAULT_ZOOM } from "../libs/defaults";
import Engine from "../libs/engine";

export interface FooterProps {
  engine: Engine | null;
}

const ZOOM_SCALE = 1.1;

export const Footer = (props: FooterProps) => {
  const [scale, setScale] = useState(DEFAULT_ZOOM);

  const zoom = (zoomDirection: "in" | "out") => {
    const stage = props.engine?.stage;

    if (typeof stage !== "undefined") {
      const oldScale = stage.scaleX();
      const pointer = stage.getPointerPosition();

      if (pointer) {
        const mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };

        let direction = zoomDirection == "in" ? 1 : -1;
        let newScale = direction > 0 ? oldScale * ZOOM_SCALE : oldScale / ZOOM_SCALE;

        stage.scale({ x: newScale, y: newScale });

        // stage.position({
        //   x: pointer.x - mousePointTo.x * newScale,
        //   y: pointer.y - mousePointTo.y * newScale,
        // });
      }
    }
  };

  const handleZoomIn = () => {
    zoom("in");
    // setScale(scale + 0.2);
    // props?.engine?.stage.scale({
    //   x: scale,
    //   y: scale,
    // });
  };

  const handleZoomOut = () => {
    zoom("out");
    // setScale(scale - 0.2);
    // props?.engine?.stage.scale({
    //   x: scale,
    //   y: scale,
    // });
  };

  const handleZoomReset = () => {
    setScale(1.0);
    props?.engine?.stage.scale({
      x: 1.0,
      y: 1.0,
    });
  };

  useEffect(() => {
    if (props.engine) {
      props.engine.stage.scale({
        x: scale,
        y: scale,
      });
    }
  });

  return (
    <Box id="footer" px={16} py={2}>
      <Group grow position="apart">
        <div></div>
        <div>
          <Button size="xs" variant="default" onClick={handleZoomIn}>
            +
          </Button>
          <Button size="xs" variant="default" onClick={handleZoomReset}>
            Reset
          </Button>

          <Button size="xs" variant="default" onClick={handleZoomOut}>
            -
          </Button>
        </div>
        <div></div>
      </Group>
    </Box>
  );
};
